import React from "react";
import { Container } from "react-bootstrap";
import getImagePath from "helpers/get-image-path";
import RichText from "components/rich-text";

import {
  mediaInfo, mediaInfoImg, mediaInfoDescription,
  mediaInfoFigure, mediaInfoContent
} from "./style.mod.scss";

const BottomMediaInfo = ({ data }) => {
  const { sectionImage, sectionText } = data;

  return (
    <div className={mediaInfo}>
      <Container>
        <div className={mediaInfoContent}>
          <div className={mediaInfoFigure}>
            <img src={getImagePath(sectionImage)} alt={sectionImage?.alt} className={mediaInfoImg} />
          </div>
          <RichText className={mediaInfoDescription} blocks={sectionText} />
        </div>
      </Container>
    </div>
  );
};

export default BottomMediaInfo;
